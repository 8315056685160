import { ART_STORE, ALBUMS } from '../../consts/photographyConsts';
import {
  serverItemsToProGallery,
  proGalleryItemsToWixCode,
} from '@wix/pro-gallery-items-formatter';
import { WixCodeApi } from '@wix/common-pro-gallery-worker-wrapper';
import { buildStyleParams } from './galleryStyleBuilder';

interface SetNewSettingsFuncParams {
  commonViewerScript: any;
  setProps: any;
  storeManager: any;
  styleParams: any;
  type: string;
  compId: string;
  consentPolicy: any;
  albumData: AlbumData;
  isInBuilder: boolean;
}

interface CreateGalleryWixCodeApiParams extends SetNewSettingsFuncParams {
  isMobile: boolean;
}

function isPgItem(item: any) {
  return item.itemId;
}

function isWixCodeItem(item: any) {
  return (item?.src && item.src.indexOf('wix:') > -1) || item?.isWixCodeItem;
}

// TODO: move to different file
const getSetNewSettingsFunc = ({
  commonViewerScript,
  setProps,
  storeManager,
  styleParams,
  type,
  compId,
  consentPolicy,
  albumData,
  isInBuilder,
}: SetNewSettingsFuncParams) =>
  function (settings: any) {
    const albumsSettings = {
      ...settings,
      albumId: albumData.settings.id,
      ...albumData.settings,
      freeArtStore: !albumData.settings.isStoreAlbum,
      downloadImageQuality: albumData.settings.downloadImageQuality,
    };
    const { watermark, galleryId } = albumsSettings;
    const store: any = commonViewerScript.getPgStore();
    watermark && setProps({ watermarkWixCode: watermark });
    galleryId && (store.galleryId = galleryId);
    store.fetcher.setSettings(albumsSettings);
    if (!commonViewerScript.isSSR() && isInBuilder) {
      storeManager
        .initStoreWixCodeApi(
          albumsSettings,
          styleParams,
          ALBUMS.ALBUMS_APP_DEF_ID,
          true,
        )
        .then(({ additionalProviderParams, connectedProviders }: any) => {
          additionalProviderParams &&
            (store.freeArtStore = additionalProviderParams.freeArtStore);
          setProps(
            storeManager.getInitalProps(
              { compId, setProps, type, consentPolicy },
              connectedProviders,
              ALBUMS.ALBUMS_APP_DEF_ID,
              additionalProviderParams,
            ),
          );
        });
    }
  };

export default class GalleryWixCodeApiManager {
  galleryWixCodeApi: WixCodeApi['api'] | undefined;
  galleryWixCodeActions: GalleryWixCodeApiFunctions;
  pgStore: any;
  isMobile: boolean;
  constructor() {
    this.init = this.init.bind(this);
    this.generateGalleryWixCodeApi = this.generateGalleryWixCodeApi.bind(this);
    this.createGalleryWixCodeActions = this.createGalleryWixCodeActions.bind(
      this,
    );
    this.galleryWixCodeActions = {
      setItems: () => {},
      setStyleParams: () => {},
      setSettings: () => {},
    };
    this.isMobile = false;
  }

  async init({
    commonViewerScript,
    setProps,
    storeManager,
    styleParams,
    type,
    compId,
    consentPolicy,
    albumData,
    isInBuilder,
    isMobile,
  }: CreateGalleryWixCodeApiParams) {
    this.isMobile = isMobile;
    this.generateGalleryWixCodeApi({
      commonViewerScript,
      setProps,
      storeManager,
      styleParams,
      type,
      compId,
      consentPolicy,
      albumData,
      isInBuilder,
      isMobile,
    });
    this.createGalleryWixCodeActions({
      galleryWixCodeApi: this.galleryWixCodeApi,
    });
    this.pgStore = commonViewerScript.pgStore;
  }

  setItems(items: any[]) {
    this.pgStore.fetcher.items = items;
    this.pgStore.items = [];
    this.pgStore.loadInitialItems(false);
  }
  get items() {
    return this.galleryWixCodeApi?.items;
  }

  generateGalleryWixCodeApi({
    commonViewerScript,
    setProps,
    storeManager,
    styleParams,
    type,
    compId,
    consentPolicy,
    albumData,
    isInBuilder,
  }: CreateGalleryWixCodeApiParams) {
    const galleryWixCodeApi = new WixCodeApi();
    this.galleryWixCodeApi = galleryWixCodeApi.generateApi({
      proGalleryStore: commonViewerScript.getPgStore(),
      setNewStyleParams(sp: any) {
        commonViewerScript.handleNewStyleParams({
          ignoreSavedConnectedProviders: true,
          ...sp,
        });
      },
      setClickedIdx(clickedIdx: any) {
        commonViewerScript.setClickedIdx(clickedIdx);
      },
      // @ts-expect-error TODO: check why type is not working
      setNewSettings: getSetNewSettingsFunc({
        commonViewerScript,
        setProps,
        storeManager,
        styleParams,
        type,
        compId,
        consentPolicy,
        albumData,
        isInBuilder,
      }),
    });
  }

  createGalleryWixCodeActions({
    galleryWixCodeApi,
  }: {
    galleryWixCodeApi: WixCodeApi['api'];
  }) {
    this.galleryWixCodeActions = {
      setStyleParams: (sp) => {
        if (galleryWixCodeApi) {
          galleryWixCodeApi.options = {
            styleParams: buildStyleParams(sp, {
              isMobile: this.isMobile,
            }),
            settings: false,
          };
        }
      },
      setItems: (items: any[]) => {
        if (galleryWixCodeApi && items) {
          let wixCodeItems = [];
          if (items.length > 0) {
            if (isWixCodeItem(items[0])) {
              wixCodeItems = items;
            } else {
              let pgItems = [];
              if (isPgItem(items[0])) {
                pgItems = items;
              } else {
                pgItems = serverItemsToProGallery(items);
              }
              wixCodeItems = proGalleryItemsToWixCode(pgItems);
            }
          }
          galleryWixCodeApi.items = wixCodeItems;
        }
      },
      setSettings: (settings: any) => {
        if (galleryWixCodeApi) {
          galleryWixCodeApi.options = { styleParams: false, settings };
        }
      },
    };
  }
}
