import type { IHttpClient } from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import _ from 'lodash';

import { GALLERY_TYPE, GALLERY_TYPES } from '../common/helpers/galleryTypes';

const albumsApi = (httpClient: IHttpClient, errorMonitor?: ErrorMonitor) => {
  const getAlbum: (
    baseUrl: string,
    state?: AlbumState,
    galleryType?: GALLERY_TYPE | undefined | null,
  ) => Promise<AlbumData> = async (baseUrl, state = 'PUBLISHED', page) => {
    const endpointName =
      (page === GALLERY_TYPES.SETS && 'getAlbumSets') || 'getAlbum';
    const { data } = await httpClient
      .get<AlbumData>(
        `${baseUrl}/albums-node-server/${endpointName}?state=${state}`,
      )
      .catch((e) => {
        if (errorMonitor) {
          const wixRequestId = _.get(
            e,
            'response.headers.x-wix-request-id',
            'MISSING-WIX-REQUEST-ID',
          );
          errorMonitor.captureException(e, {
            contexts: {
              debugInfo: {
                requestId: wixRequestId,
              },
            },
          });
        }
        throw e;
      });

    return data;
  };
  return {
    getAlbum,
  };
};
export default albumsApi;
