import {
  calculateTextBoxHeightModeOptions,
  placements,
} from '../../consts/galleryConsts';

const getFontLineHeight = function (font: any) {
  if (font.value.match(/\/(\d+)px/)) {
    // lineHeight is in px
    return parseInt(font.value.match(/\/(\d+)px/)[1], 10);
  } else if (font.value.match(/\/(\d+)%/)) {
    // lineHeight is in percentage
    return font.size * (parseInt(font.value.match(/\/(\d+)%/)[1], 10) / 100);
  } else if (font.value.match(/px\/(([0-9]*[.])?[0-9]*)/)) {
    // lineHeight is in em or without any units (which means em too)
    return (
      font.size * parseFloat(font.value.match(/px\/(([0-9]*[.])?[0-9]*)/)[1]),
      10
    );
  } else {
    console.error(
      'GalleryContainer -> getFontLineHeight -> font lineHeight do not match any pattern. font value: ',
      font.value,
    );
    return font.size;
  }
};

const getHeightByContent = function (styleParams: any) {
  const itemFontSlideshow = styleParams.itemFontSlideshow,
    itemDescriptionFontSlideshow = styleParams.itemDescriptionFontSlideshow,
    allowTitle = styleParams.allowTitle,
    allowDescription = styleParams.allowDescription,
    useCustomButton = styleParams.useCustomButton,
    titlePlacement = styleParams.titlePlacement;
  if (
    titlePlacement !== placements.SHOW_ABOVE &&
    titlePlacement !== placements.SHOW_BELOW
  ) {
    return 0;
  }
  const paddingTopAndBottom = 30;
  const defaultButtonHeight = useCustomButton ? 33 : 0;
  const defaultItemFontSize = 22;
  const defaultItemDescriptionFontSize = 15;
  const spaceBetweenElements = 16;
  const spaceBetweenTitleAndDescription = 6;
  let totalSpaceBetweenElements =
    useCustomButton && (allowTitle || allowDescription)
      ? spaceBetweenElements
      : 0;
  let titleFontSize = 0;
  let descriptionFontSize = 0;
  if (allowTitle) {
    titleFontSize = itemFontSlideshow
      ? getFontLineHeight(itemFontSlideshow)
      : defaultItemFontSize;
    totalSpaceBetweenElements += allowDescription
      ? spaceBetweenTitleAndDescription
      : 0;
  }
  if (allowDescription) {
    descriptionFontSize = itemDescriptionFontSlideshow
      ? getFontLineHeight(itemDescriptionFontSlideshow)
      : defaultItemDescriptionFontSize;
  }
  return (
    10 +
    titleFontSize +
    3 * descriptionFontSize +
    paddingTopAndBottom +
    totalSpaceBetweenElements +
    defaultButtonHeight
  ); // HACK  +10 for spare place. we can not really know that this is the final font - thus, this whole calc to get the bottom info height will break one day again.
};

export const buildStyleParams = (
  styleParams: any,
  environment: GalleryEnvironment,
) => {
  const albumsStyles: GalleryAlbumsStyles = {};

  albumsStyles.layoutParams = { cropRatio: styleParams.cubeRatio };

  if (
    styleParams.calculateTextBoxHeightMode ===
    calculateTextBoxHeightModeOptions.AUTOMATIC
  ) {
    albumsStyles.textBoxHeight = getHeightByContent(styleParams);
    albumsStyles.calculateTextBoxHeightMode =
      calculateTextBoxHeightModeOptions.MANUAL;
  }

  // cast type due to bug that saved styles as string
  albumsStyles.imageMargin = Number(styleParams.imageMargin);
  albumsStyles.textBoxHeight = Number(styleParams.textBoxHeight);

  albumsStyles.slideshowInfoSize = 50;

  if (environment.isMobile) {
    albumsStyles.numberOfImagesPerRow = 1;
  }

  const builtStyleParams = {
    ignoreSavedConnectedProviders: true,
    ...styleParams,
    ...albumsStyles,
  };

  return builtStyleParams;
};
