import type { IHttpClient, ILocation } from '@wix/yoshi-flow-editor';
import albumsApi from '../api/albums-api';
import collectionsApi from '../api/collections-api';
import { defaultAlbum, defaultList } from './defaultData';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { WarmupDataManager } from './WarmupDataManager';
import { GALLERY_TYPE, GALLERY_TYPES } from '../common/helpers/galleryTypes';

let albumDataPromise: Promise<AlbumData>;
let collectionDataPromise: Promise<CollectionAndStatus>;
let collectionItemIdsPromise: Promise<(string | undefined)[]>;

export default class CollectionDataFetcher {
  isViewer: boolean;
  isTemplate: boolean;
  collectionsApi: ReturnType<typeof collectionsApi>;
  albumsApi: ReturnType<typeof albumsApi>;
  warmupData: WarmupDataManager;
  location: ILocation;

  constructor(props: {
    environment: Environment;
    httpClient: IHttpClient;
    isTemplate: boolean;
    errorMonitor: ErrorMonitor;
    warmupData: WarmupDataManager;
    location: ILocation;
  }) {
    this.getAlbumData = this.getAlbumData.bind(this);
    this.getCollectionData = this.getCollectionData.bind(this);
    this.getCollectionItemIds = this.getCollectionItemIds.bind(this);
    this.getCollectionItems = this.getCollectionItems.bind(this);
    this.isViewer = props.environment.isViewer || props.environment.isSSR;
    this.collectionsApi = collectionsApi(props.httpClient);
    this.albumsApi = albumsApi(props.httpClient, props.errorMonitor);
    this.isTemplate = props.isTemplate || props.environment.isEditor;
    this.warmupData = props.warmupData;
    this.location = props.location;
  }

  async getAlbumData({ baseUrl }: { baseUrl: string }): Promise<AlbumData> {
    if (albumDataPromise) {
      return albumDataPromise;
    }
    if (this.isTemplate) {
      return defaultAlbum;
    }
    const state = this.isViewer ? 'PUBLISHED' : 'SAVED';
    const _baseUrl = this.isViewer ? '' : 'https://www.wix.com/_api';

    const galleryType =
      this.location.path.length > 0 &&
      this.location.path[0].toLowerCase().includes(GALLERY_TYPES.SETS)
        ? (GALLERY_TYPES.SETS as GALLERY_TYPE)
        : undefined;

    albumDataPromise = this.warmupData.isExperimentEnabled
      ? this.warmupData.manageData(
          () => this.albumsApi.getAlbum(_baseUrl, state, galleryType),
          'getAlbumData',
        )
      : this.albumsApi.getAlbum(_baseUrl, state, galleryType);
    return albumDataPromise;
  }

  async getCollectionData({
    collectionId,
    baseUrl,
    forceRefetch,
  }: {
    collectionId: string;
    baseUrl: string;
    forceRefetch?: boolean;
  }): Promise<CollectionAndStatus> {
    if (forceRefetch) {
      collectionDataPromise = this.collectionsApi.getCollection(
        collectionId,
        baseUrl,
      );
    }

    if (this.isTemplate) {
      return defaultList;
    }

    if (!collectionDataPromise) {
      if (this.warmupData.isExperimentEnabled) {
        collectionDataPromise = this.warmupData.manageData(
          () => this.collectionsApi.getCollection(collectionId, baseUrl),
          'getCollectionData',
        );
      } else {
        collectionDataPromise = this.collectionsApi.getCollection(
          collectionId,
          baseUrl,
        );
      }
    }
    return collectionDataPromise;
  }
  async getCollectionItemIds({
    collectionId,
    baseUrl,
    forceRefetch,
  }: {
    collectionId: string;
    baseUrl: string;
    forceRefetch?: boolean;
  }): Promise<(string | undefined)[]> {
    if (!collectionItemIdsPromise || forceRefetch) {
      collectionItemIdsPromise = this.collectionsApi.getCollectionItemIds(
        collectionId,
        baseUrl,
      );
    }
    return collectionItemIdsPromise;
  }

  async getCollectionItems({
    collectionId,
    baseUrl,
    forceRefetch,
  }: {
    collectionId: string;
    baseUrl: string;
    forceRefetch?: boolean;
  }): Promise<MediaItem[]> {
    const albumData = await this.getAlbumData({ baseUrl });
    const collectionItemIds = await this.getCollectionItemIds({
      collectionId,
      baseUrl,
      forceRefetch,
    });
    const albumItems = albumData.sets.gallery
      .map((gallery) => gallery.items)
      .flat();
    return (collectionItemIds as string[]).map((id: string) => {
      const item = albumItems.find((_item) => _item.id === id) as MediaItem;
      return item;
    });
  }
}
