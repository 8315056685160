import { IHttpClient, IUser, IWixAPI } from '@wix/yoshi-flow-editor';
import collectionsApi from '../api/collections-api';
import { isNotListMember } from '../common/helpers/permissions';
import { getBaseUrl } from '../common/utils';
import GalleryWixCodeApiManager from '../common/WixCode/GalleryWixCodeApiManager';
import { ModalType, Status } from '../consts/collectionsConsts';
import DataFetcher from './DataFetcher';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import {
  LocalStorageCachedCapsule,
  WixStorageStrategy,
  DataCapsule,
  LocalStorageCapsule,
} from 'data-capsule';

type AppState = {
  collection: MediaCollection | any;
  hasModifiedCollection: boolean;
  user: {
    status?: Status;
    loggedIn: boolean;
  };
  members: MemberStatus[];
  collections?: CollectionAndStatus[];
  showSignUpModal?: boolean;
};

export type AppWidget = {
  name: string;
  setProps: (props: any) => void;
};

export default class AppStateManager {
  state: AppState;
  pubSub: any;
  dataFetcher: DataFetcher;
  onPermissionsChangedCBs: (() => void)[];
  registeredWidgets: AppWidget[];
  getAuthHeader: () => string;
  baseUrl: string;
  promptLogin: (options: any) => Promise<IUser>;
  galleryWixCodeApiManager: GalleryWixCodeApiManager;
  collectionsApi: any;
  pendingJoinRequest?: { resolve: () => void; reject: () => void };
  pendingLoginRequest?: { resolve: () => void; reject: () => void };
  showSignUpModal?: boolean;

  constructor(props: {
    scopedGlobalSdkApis: IWixAPI;
    pubSub: any;
    dataFetcher: DataFetcher;
    appDefId: string;
    galleryWixCodeApiManager: GalleryWixCodeApiManager;
    httpClient: IHttpClient;
    isViewer: boolean;
    errorMonitor: ErrorMonitor;
    initAppParam: any;
    flowAPI: any;
  }) {
    this.state = {
      collection: {},
      user: {
        loggedIn: props.scopedGlobalSdkApis.user.currentUser.loggedIn,
      },
      members: [],
      hasModifiedCollection: false,
    };
    this.pubSub = props.pubSub;
    this.galleryWixCodeApiManager = props.galleryWixCodeApiManager;
    this.dataFetcher = props.dataFetcher;
    this.onPermissionsChangedCBs = [];
    this.registeredWidgets = [];
    const instance = props.initAppParam.instance;
    this.getAuthHeader = () =>
      props.scopedGlobalSdkApis?.site?.getAppToken
        ? props.scopedGlobalSdkApis.site.getAppToken(props.appDefId)
        : instance;
    this.baseUrl = getBaseUrl(props.isViewer);
    this.promptLogin = props.scopedGlobalSdkApis.user.promptLogin;
    this.collectionsApi = collectionsApi(props.httpClient);
    this.showSignUpModal = false;
    this.enableShowInfoModal = this.enableShowInfoModal.bind(this);
  }

  enableShowInfoModal = () => {
    this.showSignUpModal = true;
  };

  registerWidget = (widget: AppWidget) => {
    this.registeredWidgets.push(widget);
    if (widget.name === 'headerIcon') {
      widget.setProps({ enableShowInfoModal: this.enableShowInfoModal });
    }
  };

  notifyWidgets = (props: any) => {
    this.registeredWidgets.forEach((widget) => {
      widget.setProps(props);
    });
  };

  addOnPermissionsChangedCb = (cb: () => void) => {
    this.onPermissionsChangedCBs.push(cb);
  };

  onPermissionsChanged = () => {
    this.onPermissionsChangedCBs.forEach((func) => {
      func();
    });
  };

  setUserStatus = async ({ status }: { status?: Status }) => {
    this.state.user.status = status;
    this.onPermissionsChanged();
    this.notifyWidgets({ userStatus: status });
  };

  getUserStatus = () => {
    return this.state.user.status;
  };

  getCollection = () => {
    return this.state.collection;
  };

  waitForJoin = () => {
    return new Promise<void>((resolve, reject) => {
      this.pendingJoinRequest = {
        resolve: () => {
          this.pendingJoinRequest = undefined;
          resolve();
        },
        reject: () => {
          this.pendingJoinRequest = undefined;
          reject();
        },
      };
    });
  };

  waitForLoginApproval = () => {
    return new Promise<void>((resolve, reject) => {
      this.pendingLoginRequest = {
        resolve: () => {
          this.pendingLoginRequest = undefined;
          resolve();
        },
        reject: () => {
          this.pendingLoginRequest = undefined;
          reject();
        },
      };
    });
  };

  stopShowingSignUpModal = (headerIconWidget?: AppWidget) => {
    if (headerIconWidget) {
      headerIconWidget.setProps({ stopShowingSignUpModal: true });
    }
    this.showSignUpModal = false;
  };

  login = async (origin?: string) => {
    if (this.showSignUpModal) {
      const signUpApprovePromise = this.waitForLoginApproval();
      const headerIconWidget = this.registeredWidgets.find(
        (w) => w.name === 'headerIcon',
      );
      headerIconWidget &&
        headerIconWidget.setProps({ showSignUpModal: { res: true, origin } });
      await signUpApprovePromise;

      this.stopShowingSignUpModal(headerIconWidget);
    } else {
      await this.promptLogin({ modal: true });
    }
  };

  onJoinCollection = async (origin?: string) => {
    try {
      if (!this.state.user.loggedIn) {
        await this.login(origin);
        const collectionData = await this.dataFetcher.getCollectionData({
          collectionId: this.state.collection.id,
          baseUrl: this.baseUrl,
          forceRefetch: true,
        });
        if (isNotListMember(collectionData.memberStatus)) {
          await this.collectionsApi.joinCollection(
            this.state.collection.id,
            this.getAuthHeader(),
          );
          this.setUserStatus({ status: Status.MEMBER });
          this.updateCollectionData(collectionData.mediaCollection);
          this.fetchMembers();
        } else {
          this.setUserStatus({ status: collectionData.memberStatus });
        }
      } else {
        if (isNotListMember(this.getUserStatus())) {
          await this.collectionsApi.joinCollection(
            this.state.collection.id,
            this.getAuthHeader(),
          );
          this.setUserStatus({ status: Status.MEMBER });
          this.fetchMembers();
        }
      }
      this.pendingJoinRequest && this.pendingJoinRequest.resolve();
    } catch (e: any) {
      this.pendingJoinRequest && this.pendingJoinRequest.reject();
      throw e;
    }
  };

  setMembers(members: MemberStatus[]) {
    this.state.members = members;
  }

  fetchMembers = async () => {
    const { membersRoles } = await this.collectionsApi.getCollectionMembers(
      this.state.collection.id,
      this.baseUrl,
    );
    this.setMembers(membersRoles);
    this.notifyWidgets({ membersStatus: this.state.members });
  };

  setUserLoggedIn(loggedIn: boolean) {
    this.pendingLoginRequest && this.pendingLoginRequest.resolve();
    this.state.user.loggedIn = loggedIn;
    this.notifyWidgets({ loggedIn });
  }

  updateCollectionData(newCollectionData: MediaCollection) {
    const collcetionItems =
      (this.state.collection as MediaCollection).items || [];
    this.state.collection = {
      ...newCollectionData,
      items: collcetionItems,
      itemsCount: collcetionItems.length,
    };
    this.notifyWidgets({ collection: this.state.collection });
  }

  refetchCollectionData = async () => {
    const {
      mediaCollection,
      memberStatus,
    } = await this.dataFetcher.getCollectionData({
      collectionId: this.state.collection.id,
      baseUrl: this.baseUrl,
      forceRefetch: true,
    });
    this.updateCollectionData(mediaCollection);
    this.setUserStatus({ status: memberStatus });
  };

  updateCollectionItems(items: MediaItem[]) {
    (this.state.collection as MediaCollection).items = items;
    this.state.collection.itemsCount = items.length;
    this.notifyWidgets({ collection: this.state.collection });
    this.galleryWixCodeApiManager.setItems(items);
  }

  refetchCollectionItems = async () => {
    const items = await this.dataFetcher.getCollectionItems({
      collectionId: this.state.collection.id,
      baseUrl: this.baseUrl,
      forceRefetch: true,
    });
    this.updateCollectionItems(items);
  };
  getCollectionItems = () => {
    return this.state.collection.items || [];
  };

  getCollections = async () => {
    if (!this.state.collections) {
      return this.fetchCollections();
    }
    return this.state.collections;
  };

  fetchCollections = async () => {
    this.state.collections = await this.collectionsApi.getCollections(
      this.baseUrl,
    );
    return this.state.collections;
  };

  createCollection = async ({
    name,
    privacySettings,
    description,
  }: {
    name: string;
    privacySettings: PrivacySettings;
    description: string;
  }) => {
    const {
      mediaCollectionId,
    } = await this.collectionsApi.createCollectionOnServer({
      name,
      privacySettings,
      description,
      items: [],
    });
  };

  setPimpleVisibility = (status: boolean) => {
    const headerIconWidget = this.registeredWidgets.find(
      (w) => w.name === 'headerIcon',
    );
    this.state.hasModifiedCollection = status;
    headerIconWidget?.setProps({ showPimple: status });
  };

  setWidgetsProps = (widgets: string[], props: any) => {
    const widgetToUpdate = this.registeredWidgets.filter(
      (widget: AppWidget) =>
        !!widgets.find((widgetName) => widgetName === widget.name),
    );
    widgetToUpdate &&
      widgetToUpdate.map((widget: AppWidget) => widget.setProps(props));
  };
}
