import { ArtStoreViewerScriptManager } from '@wix/common-pro-gallery-worker-wrapper';
import { ALBUMS, MEDIA_COLLECTIONS } from '../consts/photographyConsts';

export default class CollectionStoreViewerScriptManager extends ArtStoreViewerScriptManager {
  initStoreManagerPromise: Promise<StoreManager> | undefined;
  additionalProviderParams: {} | null | AdditionalProviderParams;

  constructor(context: any) {
    super(context);

    this.initStoreWixCodeApi = this.initStoreWixCodeApi.bind(this);
    this.getSectionsUrl = this.getSectionsUrl.bind(this);
    this.initStoreManagerPromise = undefined;
    this.additionalProviderParams = null;
  }

  async initStoreWixCodeApi(
    albumsSettings: any,
    styleParams: any,
    appDefId: any,
    forceRefetch?: boolean,
  ): Promise<StoreManager> {
    if (this.initStoreManagerPromise && !forceRefetch) {
      return this.initStoreManagerPromise;
    }

    this.initStoreManagerPromise = super.initStoreWixCodeApi(
      albumsSettings,
      styleParams,
      appDefId,
    ) as Promise<StoreManager>;

    return this.initStoreManagerPromise;
  }

  getSectionsUrl(): { thank_you_page: any; checkout_page: any } {
    const srcId = (this.additionalProviderParams as AdditionalProviderParams)
      ?.srcId;
    const getSectionUrl = (sectionId: string) => {
      const queryParams = `?srcId=${srcId}&appDefId=${ALBUMS.ALBUMS_APP_DEF_ID}`;
      const section = this.context
        .getContext()
        .scopedGlobalSdkApis.site?.getSectionUrl({
          sectionId,
          appDefinitionId:
            MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
        });
      section.url = section.url + queryParams;
      return section;
    };
    return {
      thank_you_page: getSectionUrl('ThankYouPage'),
      checkout_page: getSectionUrl('CheckoutPage'),
    };
  }
}
