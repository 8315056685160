import { IHttpClient } from '@wix/yoshi-flow-editor';
import { CommentsCountMap } from '../common/CommentsManager';

type GetCommentsCountRes = { [resourceId: string]: number };

const useCommentsApi = (httpClient: IHttpClient, baseUrl: string) => {
  const getCommentsCount = async ({
    collectionId,
    albumId,
  }: {
    collectionId: string;
    albumId: string;
  }): Promise<CommentsCountMap | undefined> => {
    try {
      const { data: commentsCountFromServer } =
        await httpClient.get<GetCommentsCountRes>(
          `${baseUrl}/albums-node-server/collectionsViewer/${collectionId}/commentsCount?instanceId=${albumId}`,
        );

      const commentsCount = Object.keys(commentsCountFromServer).reduce(
        (acc: CommentsCountMap, resourceId: string) => {
          acc[resourceId] = { total: commentsCountFromServer[resourceId] };
          return acc;
        },
        {},
      );

      return commentsCount;
    } catch (e) {}
  };
  return {
    getCommentsCount,
  };
};

export default useCommentsApi;
