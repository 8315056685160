import commentsApi from '../api/comments-api';
import { MEDIA_COLLECTIONS } from '../consts/photographyConsts';
import {
  OwnerLogger,
  VisitorLogger,
} from '@wix/yoshi-flow-editor/external-types/bi';
import { ModalType } from '../consts/collectionsConsts';
import { isNotListMember } from './helpers/permissions';
import type { IHttpClient, IWixAPI } from '@wix/yoshi-flow-editor';
import { addCommentOnAnItemCollection } from '@wix/bi-logger-photo-ugc/v2';
import type AppStateManager from '../viewerScriptHelpers/AppStateManager';

type FetchCommentsParams = {
  collectionId: string;
  albumId: string;
  onSuccess: (comments?: MultiCommentsData) => void;
};

type UnboxPromise<T extends Promise<any>> = T extends Promise<infer U>
  ? U
  : never;

export type CommentsController = UnboxPromise<
  ReturnType<CommentsManager['initCommentsController']>
>;

export type CommentsCountMap = { [resourceId: string]: { total: number } };

type InitCommentsParams = {
  albumData: AlbumData;
  collectionId: string;
  controllerConfig: any;
  biLogger?: VisitorLogger | OwnerLogger | null;
  appStateManager: AppStateManager;
  wixCodeApi: IWixAPI;
  commentsManager: CommentsManager;
  isMainController: boolean;
  httpClient: IHttpClient;
};

export default class CommentsManager {
  comments: CommentsCountMap;
  initiallyLoggedIn: boolean;
  commentsApi: any;
  constructor(props: any) {
    this.comments = {};
    this.initiallyLoggedIn = false;
    this.commentsApi = commentsApi(props.httpClient, props.baseUrl);
  }

  fetchComments = async ({
    albumId,
    collectionId,
    onSuccess,
  }: FetchCommentsParams) => {
    const commentsCount = await this.commentsApi.getCommentsCount({
      collectionId,
      albumId,
    });

    if (commentsCount) {
      this.comments = commentsCount;
      onSuccess();
    }
  };

  incrementCommentsCount = (itemId: string) => {
    if (this.comments[itemId]) {
      this.comments[itemId].total += 1;
    } else {
      this.comments[itemId] = { total: 1 };
    }
  };

  decrementCommentsCount = (itemId: string) => {
    if (this.comments[itemId] && this.comments[itemId].total > 0) {
      this.comments[itemId].total -= 1;
    }
  };

  initCommentsController = async ({
    controllerConfig,
    albumData,
    collectionId,
    biLogger,
    appStateManager,
    wixCodeApi,
    commentsManager,
    isMainController,
    httpClient,
  }: InitCommentsParams) => {
    const { instanceId } = controllerConfig.appParams;

    const setProps = controllerConfig.setProps;
    const onCommentsUpdate = () => {
      setProps({ comments: this.comments });
      controllerConfig.platformAPIs.pubSub.publish('commentsUpdate');
    };

    if (isMainController) {
      commentsManager.fetchComments({
        collectionId,
        albumId: albumData.settings.id,
        onSuccess: onCommentsUpdate,
      });
      this.initiallyLoggedIn = wixCodeApi.user.currentUser.loggedIn;
    }

    const preconditionCallback = async () => {
      try {
        const joinPromise = appStateManager.waitForJoin();
        if (this.initiallyLoggedIn) {
          setProps({
            actionCallbacks: [
              { callbackName: 'showModal', args: { type: ModalType.JOIN } },
            ] as Callbacks,
            onRefuseJoinRequest: appStateManager.pendingJoinRequest?.reject,
          });
        } else {
          appStateManager.onJoinCollection('comment');
        }
        await joinPromise;
        appStateManager.onPermissionsChanged();
      } catch (e: any) {
        console.error(e);
      }
    };

    const actionPreconditions = {
      isPreconditionRequiredForAction: (action: string) => {
        if (action !== 'VIEW') {
          return isNotListMember(appStateManager.getUserStatus());
        }
        return false;
      },
      preconditionCallback,
    };

    controllerConfig.platformAPIs.pubSub.subscribe(
      'commentsUpdate',
      () => {
        setProps({ comments: this.comments });
      },
      false,
    );

    const commentsModule = import('@wix/comments-ooi-client/controller');

    const commentsController = await (
      await commentsModule
    ).initializeCommentsController(controllerConfig, {
      appDefinitionId: MEDIA_COLLECTIONS.MEDIA_COLLECTIONS_APP_DEFINITION_ID,
      actionPreconditions,
      httpClient,
    });

    commentsController.watch.pagination.onChange(async (changeData: any) => {
      for (const resourceId in changeData) {
        if (resourceId !== collectionId) {
          // update is relevant only to item comments)
          const itemId = resourceId.replace(`${collectionId}_`, '');
          if (changeData[resourceId].type === 'READY' && itemId) {
            const newTotals =
              changeData[resourceId].totals.topLevelComments +
              changeData[resourceId].totals.replies;
            if (this.comments[itemId]) {
              this.comments[itemId].total = newTotals;
            } else {
              this.comments[itemId] = { total: newTotals };
            }
            biLogger &&
              biLogger.report(
                addCommentOnAnItemCollection({
                  albumID: albumData.settings.id,
                  itemId,
                  instance_id: instanceId,
                  visitorId: wixCodeApi.user.currentUser.id,
                  role: appStateManager.getUserStatus(),
                  collection_id: collectionId,
                }),
              );
            onCommentsUpdate();
          }
        }
      }
    });

    appStateManager.addOnPermissionsChangedCb(
      commentsController.permissionsChangedExternally,
    );

    return commentsController;
  };
}
