import DataFetcher from './viewerScriptHelpers/DataFetcher';
import AppStateManager from './viewerScriptHelpers/AppStateManager';
import GalleryWixCodeApiManager from './common/WixCode/GalleryWixCodeApiManager';
import { ViewerScriptContext } from '@wix/common-pro-gallery-worker-wrapper';
import { MEDIA_COLLECTIONS } from './consts/photographyConsts';
import CommentsManager from './common/CommentsManager';
import CollectionStoreViewerScriptManager from './viewerScriptHelpers/CollectionStoreViewerScriptManager';
import type {
  ILocation,
  ViewerScriptFlowAPI,
  IPlatformAPI,
  IWixAPI,
  IPlatformServices,
  InitAppForPageFn,
} from '@wix/yoshi-flow-editor';
import { WarmupDataManager } from './viewerScriptHelpers/WarmupDataManager';

const isTemplateByLocation = ({ baseUrl, query }: ILocation) => {
  return (
    baseUrl.indexOf('exposure4.wixsite.com/website-') > -1 ||
    baseUrl.indexOf('wix.com/exposure4/website-') > -1 ||
    !!query.skipItems
  );
};

export const initAppForPage: InitAppForPageFn = async (
  initAppParam,
  platformUtilitiesApi: IPlatformAPI,
  scopedGlobalSdkApis: IWixAPI,
  platformServices: IPlatformServices,
  flowAPI: ViewerScriptFlowAPI,
) => {
  let storeManager;
  const { environment, httpClient, errorMonitor, experiments } = flowAPI;
  const galleryWixCodeApiManager = new GalleryWixCodeApiManager();
  const isTemplate = isTemplateByLocation(scopedGlobalSdkApis.location);
  const baseUrl = environment.isViewer ? '' : 'https://www.wix.com/_api';

  const warmupData = new WarmupDataManager(
    scopedGlobalSdkApis.window.warmupData,
    environment,
    experiments.enabled('specs.albums.warmupDataCollections'),
  );
  const dataFetcher = new DataFetcher({
    environment,
    httpClient,
    isTemplate,
    errorMonitor,
    warmupData,
    location: scopedGlobalSdkApis.location,
  });
  const commentsManager = new CommentsManager({
    httpClient,
    baseUrl,
  });
  const appStateManager = new AppStateManager({
    scopedGlobalSdkApis,
    pubSub: platformUtilitiesApi.pubSub,
    dataFetcher,
    appDefId: initAppParam.appDefinitionId,
    galleryWixCodeApiManager,
    httpClient,
    isViewer: environment.isViewer || environment.isSSR,
    errorMonitor,
    flowAPI,
    initAppParam,
  });

  const context = new ViewerScriptContext(MEDIA_COLLECTIONS.SENTRY_DSN);
  context.setContext({
    ...initAppParam,
    platformServices,
    scopedGlobalSdkApis,
    platformUtilitiesApi,
  });

  if (!environment.isSSR && !isTemplate) {
    context.initSentry(initAppParam, scopedGlobalSdkApis, platformServices);
    storeManager = new CollectionStoreViewerScriptManager(context);
  }
  return {
    context,
    storeManager,
    dataFetcher,
    commentsManager,
    galleryWixCodeApiManager,
    appStateManager,
    isTemplate,
    warmupData,
  };
};
